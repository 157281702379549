























































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

const ytUrlPattern = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
const urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%+._~#?&//=]*)/;
@Component
export default class AddVideoButton extends Vue {
  @Prop({ default: () => 0 }) groupId!: number;
  @Getter("groups/addVideoLoading") addVideoLoading!: boolean;
  @Action("groups/addVideoFromLink") addVideoFromLinkAction!: Function;
  @Action("groups/addVideoFromFile") addVideoFromFileAction!: Function;

  link = "";
  title = "";
  description = "";
  isPinned = false;
  showDialog = false;
  linkLoading = false;
  file: File | null = null;

  linkField = false;
  fileField = false;

  // New state for confirmation dialog
  confirmationDialog = false;
  videoType: "link" | "file" | null = null;
  toggleDialog() {
    this.showDialog = !this.showDialog;
    this.title = "";
    this.description = "";
    this.link = "";
    this.fileField = false;
    this.linkField = false;
  }

  // Show confirmation dialog
  openConfirmationDialog(type: "link" | "file") {
    this.videoType = type;
    this.confirmationDialog = true;
  }

  // Confirm and proceed with adding the video
  confirmAndAddVideo() {
    if (this.videoType === "link") this.addVideoFromLink();
    else if (this.videoType === "file") this.addVideoFromFile();

    this.confirmationDialog = false;
  }

  // Add video from link
  linkErrors: string[] = [];
  toggleLinkField() {
    this.linkField = !this.linkField;
  }
  addVideoFromLink() {
    let data = {
      groupId: this.groupId,
      title: this.title,
      description: this.description,
      isPinned: this.isPinned,
      videoUri: this.link,
    };
    this.addVideoFromLinkAction(data);
    this.toggleDialog();
  }
  async parseLink(url: string) {
    if (url.length == 0 || this.linkLoading) return;

    if (!urlPattern.test(this.link)) {
      this.linkErrors.push(this.$t("global.invalidURL").toString());
      return;
    } else this.linkErrors = [];

    this.linkLoading = true;
    const match = url.match(ytUrlPattern);
    let code = match && match[7].length == 11 ? match[7] : "";

    if (code == "" && this.link.includes("v="))
      code = this.link.split("v=")[1].split("&")[0];

    if (code != "") {
      let watchUrl = "https://youtube.com/watch?v=" + code;
      let embedUrl = "https://youtube.com/embed/" + code;
      this.link = embedUrl;

      try {
        const url =
          "https://www.youtube.com/oembed?format=json&url=" + watchUrl;
        let res = await fetch(url).then(r => r.json());

        if (this.title.length == 0) this.title = res.title;
        else if (!this.description.toLowerCase().includes("original title"))
          this.description +=
            "\n" +
            this.$t("groups.original_title").toString() +
            ": " +
            res.title;

        if (!this.description.toLowerCase().includes("author"))
          this.description +=
            "\n" + this.$t("groups.author").toString() + ": " + res.author_name;
      } catch (error) {
        console.log(error);
      }
    }
    this.linkLoading = false;
  }

  // Add video from file
  toggleFileField() {
    this.fileField = !this.fileField;
  }
  handleFileChange(file: File) {
    if (!file.type.startsWith("video/")) return;

    this.file = file;
    if (this.title.length == 0)
      this.title = file.name.replace("." + file.type.replace("video/", ""), "");
  }
  addVideoFromFile() {
    if (!this.file) return;

    const data = new FormData();
    data.append("groupId", this.groupId.toString());
    data.append("title", this.title);
    data.append("description", "" + this.description);
    data.append("isPinned", "" + this.isPinned);
    data.append("videoFile", this.file);

    this.addVideoFromFileAction(data);
    this.toggleDialog();
  }
}
